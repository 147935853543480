import moment from 'moment'
import { getCurrentLocation } from './vehicleTrackData2.js'
// 以下两个时间段为作业时间
// 7:00-10:30
// 16:00-19:00
// mock机制：

// 1.设置车辆非作业时间默认位置
// aaa
const vehicleDefaultLocation = {
  1: { lng: 80.300469, lat: 41.177037, rotation: 40 },
  2: { lng: 80.300685, lat: 41.176834, rotation: 40 },
  3: { lng: 80.30082, lat: 41.176738, rotation: 40 },
  4: { lng: 80.292734, lat: 41.146652, rotation: -15 },
  5: { lng: 80.292581, lat: 41.146604, rotation: -15 },
  6: { lng: 80.292312, lat: 41.14657, rotation: 45 }
}

const vehicleWorkLocation = {
  1: { lng: 80.300469, lat: 41.177037, rotation: 190 },
  2: { lng: 80.304305, lat: 41.173718, rotation: 90 },
  3: { lng: 80.300011, lat: 41.177383, rotation: 90 },
  4: { lng: 80.289761, lat: 41.145925, rotation: 90 },
  5: { lng: 80.283571, lat: 41.150611, rotation: 90 },
  6: { lng: 80.276664, lat: 41.155737, rotation: 90 }
}

const vehicleSpeeds = {
  1: '3.5',
  2: '4.5',
  3: '5.5',
  4: '6.1',
  5: '5.8',
  6: '4.9'
}

console.log('vehicleDefaultLocation', vehicleDefaultLocation)

export const getVehicleLocationsData = map => {
  console.log('getVehicleLocationsData')
  const currentTime = moment().format('HH:mm')
  const isWorkTime =
    (currentTime >= '07:00' && currentTime <= '10:30') || (currentTime >= '16:00' && currentTime <= '19:00')
  const currentGpsTime = moment(new Date().getTime() - 2 * 60 * 1000).format('YYYY-MM-DD HH:mm:ss')
  const tmpArr = [
    {
      id: '01',
      vehicleNo: '0-01小神童',
      deviceName: '01小神童',
      icon: 'automobile',
      status: '1',
      posType: 'WIFI',
      lat: !isWorkTime ? vehicleDefaultLocation[1].lat : vehicleWorkLocation[1].lat,
      lng: !isWorkTime ? vehicleDefaultLocation[1].lng : vehicleWorkLocation[1].lng,
      hbTime: '2021-10-22 15:53:17',
      accStatus: '0',
      gpsTime: currentGpsTime, // 5分钟前定位
      activationFlag: '1',
      expireFlag: '1',
      electQuantity: '100',
      name: '新212112',
      jobNumber: '布阿依夏木·亚森',
      speed: isWorkTime ? vehicleSpeeds[1] : '',
      rotation: isWorkTime ? vehicleWorkLocation[1].rotation : vehicleDefaultLocation[1].rotation
    },
    {
      id: '02',
      vehicleNo: '0-02小神童',
      deviceName: '02小神童',
      icon: 'automobile',
      status: '1',
      posType: 'WIFI',
      lat: !isWorkTime ? vehicleDefaultLocation[2].lat : vehicleWorkLocation[2].lat,
      lng: !isWorkTime ? vehicleDefaultLocation[2].lng : vehicleWorkLocation[2].lng,
      hbTime: '2021-10-22 15:51:58',
      accStatus: '0',
      gpsTime: currentGpsTime,
      activationFlag: '1',
      expireFlag: '1',
      electQuantity: '100',
      mileage: '28612.084967974606',
      name: '新122112',
      jobNumber: '布阿依夏木·亚森',
      speed: isWorkTime ? vehicleSpeeds[2] : '',
      rotation: isWorkTime ? vehicleWorkLocation[2].rotation : vehicleDefaultLocation[2].rotation
    },
    {
      id: '03',
      vehicleNo: '0-03小神童',
      deviceName: '03小神童',
      icon: 'automobile',
      status: '1',
      posType: 'WIFI',
      lat: !isWorkTime ? vehicleDefaultLocation[3].lat : vehicleWorkLocation[3].lat,
      lng: !isWorkTime ? vehicleDefaultLocation[3].lng : vehicleWorkLocation[3].lng,
      hbTime: '2021-10-22 15:49:00',
      accStatus: '0',
      gpsTime: currentGpsTime,
      activationFlag: '1',
      expireFlag: '1',
      electQuantity: '46',
      mileage: '112612.084967974606',
      name: '新111212',
      jobNumber: '阿依夏木·吾拉依木',
      speed: isWorkTime ? vehicleSpeeds[3] : '',
      rotation: isWorkTime ? vehicleWorkLocation[3].rotation : vehicleDefaultLocation[3].rotation
    },
    {
      id: '04',
      vehicleNo: '0-04小神童',
      deviceName: '04小神童',
      icon: 'automobile',
      status: '0',
      posType: 'WIFI',
      lat: !isWorkTime ? vehicleDefaultLocation[4].lat : vehicleWorkLocation[4].lat,
      lng: !isWorkTime ? vehicleDefaultLocation[4].lng : vehicleWorkLocation[4].lng,
      hbTime: '2021-10-22 11:43:09',
      accStatus: '0',
      gpsTime: currentGpsTime,
      activationFlag: '1',
      expireFlag: '1',
      electQuantity: '0',
      mileage: '232612.084967974606',
      name: '新111213',
      jobNumber: '王荣',
      speed: isWorkTime ? vehicleSpeeds[4] : '',
      rotation: isWorkTime ? vehicleWorkLocation[4].rotation : vehicleDefaultLocation[4].rotation
    },
    {
      id: '05',
      vehicleNo: '0-05小神童',
      deviceName: '05小神童',
      icon: 'per',
      status: '1',
      posType: 'WIFI',
      lat: !isWorkTime ? vehicleDefaultLocation[5].lat : vehicleWorkLocation[5].lat,
      lng: !isWorkTime ? vehicleDefaultLocation[5].lng : vehicleWorkLocation[5].lng,
      hbTime: '2021-10-22 15:49:31',
      accStatus: '0',
      gpsTime: currentGpsTime,
      activationFlag: '1',
      expireFlag: '1',
      electQuantity: '98',
      name: '新221222',
      mileage: '238612.084967974606',
      jobNumber: '亚森·要力瓦斯',
      speed: isWorkTime ? vehicleSpeeds[5] : '',
      rotation: isWorkTime ? vehicleWorkLocation[5].rotation : vehicleDefaultLocation[5].rotation
    },
    {
      id: '06',
      vehicleNo: '0-06小神童',
      deviceName: '06小神童',
      icon: 'automobile',
      status: '0',
      posType: 'WIFI',
      lat: !isWorkTime ? vehicleDefaultLocation[1].lat : vehicleWorkLocation[6].lat,
      lng: !isWorkTime ? vehicleDefaultLocation[1].lng : vehicleWorkLocation[6].lng,
      hbTime: '2021-10-22 13:03:21',
      accStatus: '0',
      gpsTime: currentGpsTime,
      activationFlag: '1',
      expireFlag: '1',
      electQuantity: '0',
      mileage: '998612.084967974606',
      name: '新112222',
      jobNumber: '王泽新',
      speed: isWorkTime ? vehicleSpeeds[6] : '',
      rotation: isWorkTime ? vehicleWorkLocation[6].rotation : vehicleDefaultLocation[6].rotation
    }
  ]
  if (isWorkTime) {
    Object.assign(tmpArr[0], getCurrentLocation(map, '1', 0))
    Object.assign(tmpArr[1], getCurrentLocation(map, '2', 1))
    Object.assign(tmpArr[2], getCurrentLocation(map, '3', 2))

    Object.assign(tmpArr[3], getCurrentLocation(map, '4', 0))
    Object.assign(tmpArr[4], getCurrentLocation(map, '5', 1))
    Object.assign(tmpArr[5], getCurrentLocation(map, '6', 2))
  }
  return tmpArr
}
